import React from 'react'
import {withRouter} from 'react-router-dom'
import MenuCategoryListView from './MenuCategoryListView'
import openPhotoSwipe from '../../react/openPhotoSwipeGallery'
import {Consumer} from '../../react/ShopDataContainer'
// import AutoMenuNavigator from './AutoMenuNavigator'


class AutoMenu extends React.PureComponent {
  constructor() {
    super();
    let records = []

    this.state = { menu: records, info: {}, galleryIsOpen: false };
    this.openPhotoSwipeGallery = this.openPhotoSwipeGallery.bind(this)
    this.onAddCurrentSlideToCart = this.onAddCurrentSlideToCart.bind(this)

    this.focusRef = React.createRef()
  }

  componentDidMount () {
    let productIdToFocus = null
    try {
      productIdToFocus = window['product-id-to-focus']
      // console.log(productIdToFocus)
    } catch(e) {}

    if (!productIdToFocus && this.props.shouldFocus) {
      try {
        console.log('focusing to menu')
        this.focusRef.current.focus()
      } catch(e) {
      }
    }

    if (productIdToFocus && this.props.shouldFocus) {
      console.log('🐞 focusing: ' + productIdToFocus)
      try { window['product-id-to-focus'] = null } catch(e) {}
      try {

        const productEle = document.querySelector(`#p_${productIdToFocus}`)
        productEle.setAttribute('tabindex', '-1')
        productEle.focus()
        productEle.scrollIntoView()
      } catch(e) {
        console.log(e)
      }
    }
  }

  onAddCurrentSlideToCart() {
    // console.log('clicked')
    if (!this.gallery) { return }
    let product = this.gallery.currItem && this.gallery.currItem.product
    // let cartItemId = global.__addToCart(product)
    this.gallery.close()
    this.props.history.push(`/products/${product.id}`)

  }
  openPhotoSwipeGallery(productId) {
    let allProducts = []
    this.props.menu.subMenus.forEach(({products}) => allProducts = [...allProducts, ...products])
    let gallery = openPhotoSwipe(productId, allProducts)
    this.gallery = gallery
    this.setState({ galleryIsOpen: true })
    gallery.listen('close', () => {
      this.setState({ galleryIsOpen: false })
      this.gallery = null
    })

  }

  render() {
    console.log('rendering long ass menu')

    let {menu, settings} = this.props
    settings = settings || {}

    if (!menu || !menu.subMenus) {return null}

    const {name, address} = {}

    let subMenus = menu.subMenus
    // filter based on ids
    if (this.props.categorysToShow) {
      subMenus = subMenus.filter(({id}) => {
        return (this.props.categorysToShow.indexOf(id) > -1)
      })
    }
    // filter based on name
    if (this.props.legacy__namesToShow) {
      subMenus = subMenus.filter(({name}) => {
        return (this.props.legacy__namesToShow.indexOf(name) > -1)
      })
    }

    if (this.props.tagsToShow) {
      subMenus = subMenus.filter(({tag}) => {
        if (!tag) { return false}
        let hasTag = false
        this.props.tagsToShow.forEach((tagToShow) => {
          hasTag = tag.indexOf(tagToShow) !== -1
          if (hasTag) { return false }
        })
        return hasTag
      })
    }

    if (this.props.subMenuFilter) {
      subMenus = subMenus.filter(this.props.subMenuFilter)
    }
    if (this.props.subMenuDisableFilter) {
      subMenus = subMenus.map(this.props.subMenuDisableFilter)
    }

    if (this.props.shopId) {
      subMenus = subMenus.filter(({shopId}) => {
        return this.props.shopId === shopId
      })
    }

    return (<div className="menu-story" role="main">

      <header
        style={{
          opacity: 0,
          height: 0
        }}

        >

        <h1
          tabIndex={-1}
          ref={this.focusRef}
          >Ordering Menu</h1>
      </header>

      {/* <AutoMenuNavigator
        subMenus={subMenus}
      /> */}

      <section>
        {subMenus
            .filter(({tag}) => {
              if (tag && (tag.indexOf('hide') !== -1)) {
              return false
              }
              return true
            })
            .map(({id, name, products, description, disableOrder, tag}) => {
            if (this.props.disableOrder) {
              disableOrder = true
            }
            if (this.props.productFilter) {
              products = products.filter((product) => {
                return this.props.productFilter(product, {name})
              })
            }
            const tagCSS = tag ? ` ${tag}` : ""
            return(
              <div className={"category" + tagCSS} key={name && name.en}>

                <header className="category__header">
                  <h3
                    id={`menu-category-${id}`}
                    className="category-name">
                    <span className="text">{name && name.en}</span>
                  </h3>

                  {description && <p className="category__description">
                    {description && description.en}
                  </p>}
                </header>



                <MenuCategoryListView
                  categoryName={name && name.en}
                  disableOrder={disableOrder}
                  items={products}
                  width={this.props.width || 180}
                  openPhotoSwipeGallery={this.openPhotoSwipeGallery}
                />

              </div>
            )
          })
        }



      </section>

      {/* {this.state.galleryIsOpen && <PhotoswipePortal
        onAddCurrentSlideToCart={this.onAddCurrentSlideToCart}
      />} */}
      {this.state.galleryIsOpen && <div className="test-test">
        <button onClick={this.onAddCurrentSlideToCart}>Add To Cart</button>
      </div>}
    </div>)


  }
}

// const AutoMenuWithRouter = withRouter(AutoMenu)
const AutoMenuWithRouter = withRouter(AutoMenu)
const WithContext = (props) => {
  return(
    <Consumer>
      {({menu, settings}) => {
        return(<AutoMenuWithRouter {...props} menu={menu} settings={settings}></AutoMenuWithRouter>)
      }}
    </Consumer>
  )
}

export default WithContext
